.details {
  margin-bottom: 15px;
}

.summary {
  color: #f5f5f5;
  font-size: 15px;
  font-weight: 700;
}

.channel,
.published {
  color: #6c757d;
  margin-bottom: 3px;
  margin-top: 4px;
  font-size: 13px;
}

.channel>span,
.published>span {
  font-weight: 700;
}