@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background-color: rgb(36, 39, 41) !important;
}

::-webkit-scrollbar {
  width: 3.5px;
}

::-webkit-scrollbar-thumb {
  background-color: #464b50;
}